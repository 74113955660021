.section_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
    color: white;
    width: 100%;
    padding: 5px;
}

.section_title {
    font-weight: bold;
    font-size: 1.2rem;
}

.section_body {
    display: flex;
    flex-direction: row; 
    gap: 5px;
    padding: 5px;
}

.list_section_body {
    display: flex;
    flex-direction: column; 
    gap: 5px;
    padding: 5px;
}

.system_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.system_list_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}